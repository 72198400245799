<template>
    <div class="pa-10">
        <h1>Investor Relations</h1>
        <v-row>
            <v-col :md='8'>
                Mathison Projects partners up with companies and teams, large and small, to provide hands on development solutions for anything. We service hospitality, banking, e-commerce, insurance, medical, energy, telecommunications, and more. Despite Mathison Project's youth, we aggressively are seeking to constantly be growing and become a go to solution provider for many companies and teams across the USA to improve their current software for all users.
            </v-col>
            <v-col></v-col>
        </v-row>
        <v-row>
            <v-col class='text-center' :md='12'>
                <h2>Why Invest</h2>
            </v-col>
            <v-col :md='4'>
                <v-card>
                    <v-card-text>
                        Fast Fact
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col :md='4'>
                <v-card>
                    <v-card-text>
                        Fast Fact
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col :md='4'>
                <v-card>
                    <v-card-text>
                        Fast Fact
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <h3>News</h3>
                <v-card>
                    <v-list>
                        <v-list-item-group>
                            <v-list-item>test</v-list-item>
                            <v-list-item>test</v-list-item>
                        </v-list-item-group>
                    </v-list>
                </v-card>
            </v-col>
            <v-col>
                <h3>Events</h3>
                <v-card>
                    <v-list>
                        <v-list-item-group>
                            <v-list-item>test</v-list-item>
                            <v-list-item>test</v-list-item>
                        </v-list-item-group>
                    </v-list>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col :xl='2' :lg='3' :md='3' class='text-center'>
                <h1>Q3</h1>
                <h2>2021</h2>
            </v-col>
            <v-col :xl='10' :lg='9' :md='9'>
                <v-row>
                    <v-col :md='12' class='text-center'>
                        <h2>Quarterly Results</h2>
                    </v-col>
                    <v-col>
                        <v-btn block color='info'>
                            <v-icon x-large text>mdi-file-chart</v-icon>
                            {{ baseTitle }} Financial Results
                        </v-btn>
                    </v-col>
                    <v-col>
                        <v-btn block color='info'>
                            <v-icon x-large text>mdi-file-pdf</v-icon>
                            {{ baseTitle }} Financial Statement
                        </v-btn>
                    </v-col>
                    <v-col>
                        <v-btn block color='info'>
                            <v-icon x-large text>mdi-table</v-icon>
                            {{ baseTitle }} Financial Data Tables
                        </v-btn>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    export default {
        name      : "base-investors-page-component",
        props     : {},
        model: {
            event: 'updateModel'
        },
        components: {},
        data()      {
            return {}
        },
        computed  : {
            baseTitle() {
                return '3Q 2021'
            }
        },
        methods   : {},
        watch     : {}
    }
</script>

<style scoped>

</style>